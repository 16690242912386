<template>

    <v-card
        v-if="loggedIn"
        class="mx-auto mt-6"
        style="min-height: 700px;"    
    >    
        <v-toolbar
            ligth
            flat
            color="green lighten-4"
        >
            <v-card-title class="text-h6 font-weight-regular">
                {{ item.name }}
            </v-card-title>
            <v-spacer></v-spacer>

            <v-menu
          offset-y
          min-width="250"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-land-fields</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Feld wählen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list 
            style="max-height: 500px"
            class="overflow-y-auto"
            >
            
            <v-list-item
                    v-for="(item, i) in fields.items"
                    :key="i"
                >
                <v-list-item-title>
                <v-btn
                  text
                  @click="changeField(item.id)"                 
                >
                      {{ item.name }}
                </v-btn>
                
                
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>                    

                </v-toolbar>
                
                <v-toolbar>
                        <v-tabs
                            v-model="tabs"
                            grow
                            :show-arrows="showArrows"
                        >
                            <v-tabs-slider></v-tabs-slider>
                            <v-tab
                                href="#tabHome"
                                class="primary--text"
                            >
                                <v-icon >mdi-home</v-icon>
                            </v-tab>
                            <v-tab
                                href="#tabDashboard"
                                class="primary--text"
                            >
                                <v-icon >mdi-monitor-dashboard</v-icon>
                            </v-tab>
                            <v-tab
                                href="#tabWork"
                                class="primary--text"
                            >
                                <v-icon >mdi-progress-wrench</v-icon>
                            </v-tab>
                            <v-tab v-if="item.cultivation_species_id==1000"
                                href="#tabWine"
                                class="primary--text"
                            >
                                <v-icon >mdi-fruit-grapes</v-icon>
                            </v-tab>
                            <v-tab v-if="item.sprayerEnabled"
                                href="#tabSprayer"
                                class="primary--text"
                            >
                                <v-icon >mdi-sprinkler-fire</v-icon>
                            </v-tab>
                            <v-tab v-if="item.isLeased"
                                href="#tabIsLeased"
                                class="primary--text"
                            >
                                <v-icon>mdi-map-clock</v-icon>
                            </v-tab>
                        </v-tabs>
                 
                </v-toolbar> 

                <v-tabs-items v-model="tabs">
                    <v-tab-item value="tabHome">
                        <v-row class="ma-2">
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col
                                    cols="12"
                                    >
                                    <v-text-field
                                            v-model="item.name"
                                            label="Name"
                                            readonly                                            
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                    cols="12"
                                    >
                                        <v-text-field
                                            v-model="item.area_ha"
                                            label="Flache in ha"
                                            readonly                                            
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                    cols="12"
                                    >
                                        <v-text-field
                                            v-model="item.cultivation_species_caption"
                                            label="Anbauart"
                                            readonly                                            
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row v-if="item.cultivation_species_id==1000">
                                    <v-col
                                    cols="12"
                                    >
                                        <v-text-field
                                            v-model="item.meta.wine.type"
                                            label="Sorte"
                                            readonly                                            
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-spacer></v-spacer>
                            </v-col>
                            <v-col cols="12" md="6">
                            <MaskGeoData :item="item" ></MaskGeoData>
                            </v-col>
                        </v-row>

                    </v-tab-item>
                    <v-tab-item value="tabWine" v-if="item.cultivation_species_id==1000">
                        <v-row class="ma-2">
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col
                                    cols="12"
                                    >
                                        <v-text-field
                                            v-model="item.meta.wine.type"
                                            label="Sorte"
                                            readonly                                            
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                    cols="12"
                                    >
                                        <v-text-field
                                            v-model="item.meta.wine.date_of_cultivation"
                                            label="Pflanzdatum"
                                            readonly                                            
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                    cols="12"
                                    >
                                        <v-text-field
                                            v-model="item.meta.wine.row_distance"
                                            label="Gassenbreite"
                                            suffix="m"
                                            readonly                                            
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                    cols="12"
                                    >
                                        <v-text-field
                                            v-model="item.meta.wine.grapevine_distance"
                                            label="Stockabstand"
                                            suffix="m"
                                            readonly                                            
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-spacer></v-spacer>
                            </v-col>
                            <v-col cols="12" md="6">
                            
                            </v-col>
                        </v-row>

                    </v-tab-item>
                    <v-tab-item value="tabIsLeased" v-if="item.isLeased">
                        <v-row class="ma-2">
                            <v-col cols="12" md="5">
                                <v-row>
                                    <v-col
                                    cols="12"
                                    >
                                        <v-radio-group v-model="item.leased.leasedout" row>
                                            <v-radio
                                                label="Gepachtet"
                                                :value="0"
                                                readonly
                                            ></v-radio> 
                                            <v-radio
                                                label="Verpachtet"
                                                :value="1"
                                                readonly
                                            ></v-radio> 
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                    cols="12"
                                    >
                                        <v-text-field
                                            :value="findContact(item.leased.contact_id)"
                                            :label="item.leased.leasedout ? 'Pächter' : 'Verpächter'"
                                            readonly                                            
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                    cols="12"
                                    >
                                        <v-text-field
                                            v-model="item.leased.startdate"
                                            label="Läuft seit"
                                            readonly                                            
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                    cols="12"
                                    >
                                        <v-text-field
                                            v-model="item.leased.expirationdate"
                                            label="Läuft aus am"
                                            readonly                                            
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                    cols="12"
                                    >
                                        <v-text-field
                                            v-model="item.leased.payday"
                                            label="Zahltag"
                                            readonly                                            
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                    cols="12"
                                    >
                                        <v-text-field
                                            v-model="item.leased.amount"
                                            label="Pacht Betrag/Jahr"
                                            prefix="€"
                                            readonly                                            
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-spacer></v-spacer>
                            </v-col>
                            <v-col cols="12" md="7">
                                <v-card elevation="0">
                                    <v-card-title  class="pb-0">
                                        History
                                    </v-card-title>
                                    <v-data-table
                                    ref="datatable"
                                    :headers="headers"
                                    :items="leasedHistoryItems"
                                    hide-default-footer
                                    :items-per-page="-1"
                                    >

                                        <template v-slot:item.actions="{ item }">
                                        <v-icon    
                                            :disabled="(!access.edit_accept || item.roleLevel > user.roleLevel) && user.id != item.id"
                                            class="mr-2"
                                            @click="editItem(item)"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        <v-icon
                                            :disabled="!access.delete_accept || item.roleLevel > user.roleLevel"
                                            class="mr-2"
                                            @click="deleteItem(item)"
                                        >
                                            mdi-delete
                                        </v-icon>
                                        </template>
                                        <template v-slot:item.amount="{ item }">
                                            € {{item.amount}}
                                        </template>
                                    </v-data-table>
                                </v-card>                            
                            </v-col>
                        </v-row>

                    </v-tab-item>
                </v-tabs-items>
            </v-card>
  
</template>


<script>
export default {

    components:{
            MaskGeoData: () => import("../../components/ctrl/StatusMask/fieldGeoData.vue")           
        },  

    data () {
        return {
            name: "FieldViewer",
            compid:'',
            actField: '',
            tabs: null,
            headers: [
                { text: 'Actions', value: 'actions', sortable: false },
                { text: 'Startdatum', align: 'start',value: 'startdate',},
                { text: 'Enddatum', value: 'expirationdate' },
                { text: 'Kosten/Jahr', align:'center', value: 'amount'},
    
            ],  
            
 
        }
    },

  
    computed: {


        loggedIn: function() {

            return this.$store.state.auth.status.loggedIn
            
        },
 
        user(){
            return this.$store.state.auth.user
        },

        leasedHistoryItems(){
            const tmp = this.$store.state.app.actfield.leased_history
            if(!tmp){
                return []
            }

            return tmp
        },
        
        item(){
             
            const tmp = this.$store.state.app.actfield
            if(!tmp){
                return {
                    name: "N/A"
                }
            }

            return tmp
        },
        access(){
            return this.$store.state.app.access
        },

        company() {
            return this.$store.state.app.actCompany.meta
        },

        fields(){
            return this.$store.state.app.fields

        },

        refresh(){
            return this.$store.state.app.refresh
        },

        showArrows(){
        
            let res = true
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': 
                    res = true
                    break;
            case 'sm':
                    res = true
                    break;
            case 'md':
                    res = true
                    break;
            // case 'lg':
            //         res = true
            //         break;
            //   case 'md': res = false
            //   case 'lg': res = false
            //   case 'xl': res = false
            }
            console.debug("showArrows", res)
            return res
        },
        
    },
    methods:{

        findContact(id){
            console.log('findContact', id)
            let res = ""
            const user = this.user.linkedContacts.find(item => item.id === id);
            if(user)
                res = user.firstname + " " + user.lastname

            return res
        },


        changeField(id){
            console.debug('changeField',id);
            this.refreshContent(id)
        },

        refreshContent(id= null){
            console.debug('refreshContent',this.$route.params, id);

            if(!id)
                id = this.$route.params.fieldid

            this.$store.dispatch("app/getApiSetStore", {path:"api/company/access", param:{store:"access", id:this.company.id, content:"fields"}
            }).then( ()=>{    
               return this.$store.dispatch("app/getApiSetStore", {path:"api/company/field", param:{store:"actfield", id:this.company.id, fieldid:id}})
            }).then( ()=>{  
                this.actField = this.item.id  
            }).catch((error)=>{
                console.error(error);
            })

            
            // this.$store.dispatch("getFields")


        },
    },

    mounted() {

        console.debug('mounted'); 
        this.refreshContent();

    },

    watch: { 
        fields(newVal) { // watch it
            console.debug('Prop changed: fields', newVal);    
            // this.items.fields = newVal
        },



       items(newVal) { // watch it
            console.debug('Prop changed: items', newVal);    
            // this.items.fields = newVal
        },          
        company(newVal) { // watch it
            console.debug('Prop changed: company', newVal);    
            this.refreshContent();
        }    
    }      

}
</script>